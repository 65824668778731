import { ShoppbudAPI } from '@inhouse-market/sdk';

import { endpoints } from "../env";


import Cookies from 'js-cookie';

export * from '@inhouse-market/sdk';

const apiSDK = new ShoppbudAPI({
  async getTokenFn() {
    const authToken = Cookies.get('authorization');
    return authToken!;
  },
  platformAndVersion: "web", 
  appVersion: process.env.VERSION || '', 
  url: endpoints.api, 
  userId: Cookies.get('userId') || '',
  async getDeviceId() { 
    return navigator.userAgent;
  },
});

export default apiSDK;
