import {
	Montserrat_400Regular,
	Montserrat_500Medium,
	Montserrat_600SemiBold,
	Montserrat_700Bold,
	useFonts,
} from "@expo-google-fonts/montserrat";
import { NavigationContainer } from "@react-navigation/native";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppLoading from "expo-app-loading";
import { onMessage } from "firebase/messaging";
import React from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Provider } from "react-native-paper";
import { CartProvider } from "./src/providers/cart-provider";
import { CustomProvider } from "./src/providers/custom-provider";
import { ModalProvider } from "./src/providers/modal-provider";
import { NotificationBarProvider } from "./src/providers/notification-bar-provider";
import Routes from "./src/routes/routes";
import { messaging } from "./src/services/firebase";
//
Sentry.init({
	dsn: process.env.SENTRY_DSN,
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
	],
	tracesSampleRate: 1.0,
	release: process.env.VERSION,
	autoSessionTracking: true,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0, 
	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.',
		"messaging/unsupported-browser",
		"Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
		"This browser doesn't support the API's required to use the Firebase SDK",
		"3000ms timeout exceeded",
		"(messaging/unsupported-browser)."
	],
});

export default function App() {
	let [fontsLoaded] = useFonts({
		Montserrat_400Regular,
		Montserrat_500Medium,
		Montserrat_600SemiBold,
		Montserrat_700Bold,
	});

	React.useEffect(() => {
		onMessage(messaging, (payload) => {
			const { title, body, ...options } = payload.notification;
			navigator.serviceWorker.register("firebase-messaging-sw.js");
			function showNotification() {
				Notification.requestPermission(function (result) {
					if (result === "granted") {
						navigator.serviceWorker.ready.then(function (
							registration
						) {
							registration.showNotification(title, {
								body: body,
								icon: "/icon96x96.png",
								badge: "/icon96x96.png",
							});
						});
					}
				});
			}
			showNotification();
		});
	}, []);

	const queryClient = new QueryClient()
//
	if (!fontsLoaded) {
		return <AppLoading />;
	}

	return (
		<Provider>
			<CustomProvider>
				<NavigationContainer>
					<NotificationBarProvider>
					<QueryClientProvider client={queryClient}>
						<ModalProvider>
							<CartProvider>
								<Routes />
							</CartProvider>
							</ModalProvider>
							</QueryClientProvider>
					</NotificationBarProvider>
				</NavigationContainer>
			</CustomProvider>
		</Provider>
	);
}
