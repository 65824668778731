import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { endpoints } from "../env";

const api = axios.create({
  baseURL: endpoints.api,
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  config.headers = config.headers || {};
  const headers = config.headers;

  headers['x-app-version'] = process.env.VERSION || '',
  headers['x-platform'] = "web"
  headers['x-user-id'] = Cookies.get('userId') || '',
  headers['x-device-id'] = navigator.userAgent || ''

  return config;
});

export default api;